// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// PrismJS theme
require("prismjs/themes/prism-okaidia.css")
require("prismjs/plugins/line-numbers/prism-line-numbers.css")

// css overrides
require('./src/styles/global.css')
